type ErrorMessageDetailType = {
  title: string
  message: string
}

export const ERROR_CODE_MESSAGES: { [key: string]: ErrorMessageDetailType } = {
  EV01: {
    title: 'Some meters were not found, so tests cannot be run',
    message: 'Enter an existing Meter MAC',
  },
  EV02: {
    title: 'Some meters have pending configurations',
    message:
      'Wait for the configuration to be applied and data to be collected before retrying',
  },
  EV03: {
    title: 'Primary panels are not associated with some meters.',
    message: 'Add a primary panel to the meter before running the tests again',
  },
  EV04: {
    title: 'Unable to fetch data for some meters.',
    message: 'Meter Data could not be fetched, try again later',
  },
  EV05: {
    title: 'Some meters don’t have enough data to run the checks',
    message: 'You can still run “Label Check"',
  },
  EV06: {
    title: 'EV tests cannot be run on some meters.',
    message: 'Please try again later',
  },
  EV07: {
    title: 'EV tests cannot be run on some meters.',
    message: 'Please try again later',
  },
}
